import React from 'react'

import styled from 'styled-components'

import { Link } from 'gatsby'

import Image1 from '../../images/home3.svg'
import Image2 from '../../images/home4.svg'

import {
    StyledMain,
    StyledLine,
    StyledMainContent,
    StyledMainDescription,
    StyledMainText,
    StyledMainBackground,
    StyledMainSlider,
    StyledMainCards,
    StyledMainCard
} from '../MainStyles/MainContent'

import { motion } from 'framer-motion'
import { fadeAnimation, fadeItem } from '../MainStyles/animations'
import { useScroll } from '../MainStyles/useScroll'

const Finances = () => {
    const [element, controls] = useScroll()
    return (
        <StyledFinances>
            <StyledLine />
            <StyledContent>
                <StyledDescription>
                    <StyledMainText variants={fadeAnimation} animate={controls} initial="hidden" ref={element}>
                        <motion.h2 variants={fadeItem}>Rejestracja i&nbsp;kompleksowe prowadzenie firm</motion.h2>
                        <motion.p variants={fadeItem}>Na etapie planowania działalności doradzimy jak rozpocząć
                        działalność
                        gospodarczą i&nbsp;jaką wybrać formę opodatkowania oraz ewidencji
                        księgowej.
                        Na etapie organizacji opracujemy wymagane dokumenty i&nbsp;skierujemy do
                        odpowiednich urzędów i&nbsp;instytucji. Przeprowadzamy kompleksową
                        rejestrację firm i&nbsp;organizacji.</motion.p>
                        <StyledLink to="/oferta" variants={fadeItem} whileHover={{ scale: 1.06, translateZ: '-43px', }}
                            transition={{ type: 'spring', stiffness: 300, duration: 2, }}>więcej</StyledLink>
                    </StyledMainText>
                </StyledDescription>
                <StyledSlider>
                    <StyledMainCards>
                        <StyledBackground />
                        <StyledMainCard>
                            <img src={Image1} alt="" />
                        </StyledMainCard>
                        <StyledMainCard>
                            <img src={Image2} alt="" />
                        </StyledMainCard>
                    </StyledMainCards>
                </StyledSlider>
            </StyledContent>
        </StyledFinances>
    )
}

const StyledFinances = styled(StyledMain)`
    background: linear-gradient(267.37deg, #ECEBEE 1.05%, rgba(250, 250, 250, 0.06) 122.76%);
`

const StyledContent = styled(StyledMainContent)`
    margin: 10rem 0 15rem 0;
    ${({ theme }) => theme.media.tablet} {
        flex-direction: column;
    }
    ${({ theme }) => theme.media.phone} {
        text-align: center;
    margin: 2rem 0 5rem 0;
}
`

const StyledDescription = styled(StyledMainDescription)`
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
        padding: 2rem 0;
    }
    p {
        padding: 2rem 0;
    }
    ${({ theme }) => theme.media.tablet} {
        margin-bottom: 5rem ;
    }
`

const StyledBackground = styled(StyledMainBackground)`
    background:${({ theme }) => theme.color.white};
    border-radius:  14px 0px 0px 14px;
    right: 0%;
`

const StyledSlider = styled(StyledMainSlider)`
    align-items: center;
`

const StyledLink = styled(motion(Link))`
    margin: 2rem 0;
    background: ${({ theme }) => theme.color.blue};
    border-radius: 4px;
    color: ${({ theme }) => theme.color.white};
    font-size: 2rem;
    padding: 0.6rem 10rem 0.9rem 10rem;
    font-variant: small-caps;
    box-shadow: 0px 4px 15px rgba(0, 0, 255, 0.23);
    border: none;
    transform: perspective(800px);
`
export default Finances
