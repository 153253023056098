import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
//components
import FrontPage from '../components/StyledHome/FrontPage'
import Services from '../components/StyledHome/Services'
import Finances from '../components/StyledHome/Finances'
import Optima from '../components/StyledHome/Optima'
import Contact from '../components/StyledHome/Contact'
// import Questions from '../components/StyledHome/Questions'
import Location from '../components/StyledHome/Location'

const Home = ({ path, myLocation }) => {
    return (
        <Layout path={path} myLocation={myLocation}>
            <SEO
                title="Biuro rachunkowe art7"
                description="Biuro rachunkowe art7. Lokalizacja: Jabłonka. Główną działalnością naszej firmy są usługi biura rachunkowego w zakresie prowadzenia księgowości (ryczałt, książka przychodów i rozchodów, księgi handlowe (pełna księgowość), usługi kadrowo - płacowe i podatkowe."
            />
            <FrontPage />
            <Services />
            <Finances />
            <Optima />
            <Contact />
            {/* <Questions /> */}
            <Location />
        </Layout>
    )
}


export default Home