import React from 'react'
import styled from 'styled-components'
// import OptimaImage from '../../images/optima.png'

import { StaticImage } from 'gatsby-plugin-image'

import {
    StyledMain,
    StyledLine,
    StyledMainDescription,
    StyledMainContent,
    StyledMainText,
} from '../MainStyles/MainContent'

import { motion } from 'framer-motion'
import { fadeAnimation, fadeItem } from '../MainStyles/animations'
import { useScroll } from '../MainStyles/useScroll'

const Optima = () => {
    const [element, controls] = useScroll()
    return (
        <StyledOptima>
            <StyledLine />
            <StyledContent>
                <StyledImage>
                    {/* <img src={OptimaImage} alt="" /> */}
                    <StaticImage
                        src='../../images/optima2.webp'
                        alt="Optima image"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        placeholder="blurred"
                        loading="eager"
                        layout="constrained"
                        className="optima-img"

                    />
                </StyledImage>
                <StyledDescription>
                    <StyledMainText variants={fadeAnimation} animate={controls} initial="hidden" ref={element}>
                        <StyledH2Box>
                            <motion.h2 variants={fadeItem}>Partner Comarch. </motion.h2>
                            <motion.h2 variants={fadeItem}>Wdrożenie i&nbsp;szkolenia</motion.h2>
                            <motion.h2 variants={fadeItem}> z ERP Optima</motion.h2>
                        </StyledH2Box>
                        <motion.p variants={fadeItem}>Jesteśmy autoryzowanym przedstawicielem firmy Comarch. Nie tylko
                        instalujemy i&nbsp;szkolimy w zakresie oprogramowania ale na co dzień
                        pracujemy wykorzystując oprogramowanie Optima. Comarch ERP Optima to
                        najpopularniejszy w&nbsp;Polsce program stworzony dla małych i&nbsp;średnich firm
                        z&nbsp;każdej branży. Dzięki modułom dedykowanym każdemu obszarowi
                        biznesowemu (faktury, płace, kasa/bank, sprzedaż, handel z&nbsp;magazynem
itd.) jest optymalnym systemem ERP do zarządzania firmą.</motion.p>
                        {/* <motion.button variants={fadeItem}>więcej</motion.button> */}
                    </StyledMainText>
                </StyledDescription>
            </StyledContent>
        </StyledOptima>
    )
}


const StyledOptima = styled(StyledMain)`
    background: ${({ theme }) => theme.color.lightGray};
    align-items: center;
    justify-content: center;
`
const StyledContent = styled(StyledMainContent)`
    ${({ theme }) => theme.media.tablet} {
        flex-direction: column;
    }
    margin: 10rem 0 15rem 0;
    ${({ theme }) => theme.media.phone} {
        flex-direction: column-reverse;
        text-align: center;

    margin: 2rem 0 5rem 0;
}
`

const StyledImage = styled.div`
    /* padding-left: 15rem; */
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .optima-img {
        width: 90rem;
        height:60rem;
        object-fit: contain;
        ${({ theme }) => theme.media.phone} {
            width: 50rem;
            height: 36rem;
        }
    }
    ${({ theme }) => theme.media.tablet} {
        margin-bottom: 5rem ;
        padding: 0;
    }
    ${({ theme }) => theme.media.phone} {
        align-items: center;
        margin: 4rem auto;
        width: 90%;
}
`

const StyledDescription = styled(StyledMainDescription)`
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
        /* padding: 2rem 0; */
    }
    p {
        padding: 2rem 0;
    }
    button {
        margin: 2rem 0;
    }
`

const StyledH2Box = styled.div`
    padding: 2rem 0;
`

export default Optima
