import React from 'react'

import styled from 'styled-components'
const Location = () => {
    return (
        <StyledMap>
            <StyledIframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d408.84365876300626!2d19.693637776600767!3d49.478903425055655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1spl!2spl!4v1610979828443!5m2!1spl!2spl" frameBorder="0" allowFullScreen="" aria-hidden="false" title="google-map" ></StyledIframe>
        </StyledMap>
    )
}

const StyledMap = styled.div`
    position: relative;
    overflow: hidden;
    ::before {
        top: -5px; left: 0; right: 0; height: 5px;
        content: '';
        position: absolute;
        box-shadow: 0px 10px 70px 100px#FAFAFA;
        z-index: 1;
    }
`

const StyledIframe = styled.iframe`
    display: block;
    height: 50rem;
    width: 100%;
    border: none;
`
export default Location
