import React from 'react'
import styled from 'styled-components'

import Phone from '../../images/phone.svg'
import Message from '../../images/message.svg'
import Address from '../../images/address.svg'
import Facebook from '../../images/facebook.svg'
import Instagram from '../../images/instagram.svg'

import { StyledForm, StyledText, StyledBox, StyledDescription, StyledHours } from '../StyledContact/ContactStyles'

import {
    StyledMain,
    StyledLine,
    StyledMainContent,
} from '../MainStyles/MainContent'

import { motion } from 'framer-motion'
import { fadeAnimation, fadeItem } from '../MainStyles/animations'
import { useScroll } from '../MainStyles/useScroll'

const Contact = () => {
    const [element, controls] = useScroll()
    return (
        <StyledContact>
            <StyledLine />
            <StyledContent>
                <StyledDescription>
                    <StyledText variants={fadeAnimation} animate={controls} initial="hidden" ref={element}>
                        <h2 variants={fadeItem}>Skontaktuj się z nami</h2>
                        <StyledBox variants={fadeItem}>
                            <img src={Phone} alt="" />
                            <p>515 104 483</p>
                        </StyledBox>
                        <StyledBox variants={fadeItem}>
                            <img src={Message} alt="" />
                            <p>biuro@art7.pl</p>
                        </StyledBox>
                        <StyledBox variants={fadeItem}>
                            <img src={Address} alt="" />
                            <p>Jabłonka, ul. Sobieskiego 5</p>
                        </StyledBox>
                        <StyledHours variants={fadeItem}>
                            <p>Godziny otwarcia biura: <span>08.00 - 16.00</span></p>
                        </StyledHours>
                        {/* <StyledSocials variants={fadeItem}>
                            <img src={Facebook} alt="" />
                            <img src={Instagram} alt="" />
                        </StyledSocials> */}
                    </StyledText>
                </StyledDescription>
                <StyledForm>
                    <form name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field" action="/wiadomosc-wyslana">
                        <input type="hidden" name="form-name" value="contact"></input>
                        <div className="input-data">
                            <input className="inputField" type="text" name="name" id="name" required />
                            <div className="underline"></div>
                            <label htmlFor="name">Imię i Nazwisko</label>
                        </div>
                        <div className="input-data">
                            <input className="inputField" type="text" name="email" id="email" required />
                            <div className="underline"></div>
                            <label htmlFor="email">Email</label>
                        </div>
                        <div className="text-data">
                            <textarea name="message" id="message" required></textarea>
                            <span className="line1"></span>
                            <span className="line2"></span>
                            <span className="line3"></span>
                            <span className="line4"></span>
                            <label htmlFor="message">Treść wiadomości</label>
                        </div>
                        <div hidden>
                            <input name="bot-field" />
                        </div>
                        <motion.button
                            whileHover={{ scale: 1.06, translateZ: '-43px', }}
                            transition={{ type: 'spring', stiffness: 300, duration: 2, }}
                            type="submit" className="send-input">
                            wyślij
                                {/* <img src={Arrow} alt="" className="input-arrow" /> */}
                        </motion.button>
                    </form>
                </StyledForm>
            </StyledContent>
        </StyledContact>
    )
}

const StyledContact = styled(StyledMain)`
    background: linear-gradient(267.37deg, #ECEBEE 1.05%, rgba(250, 250, 250, 0.06) 122.76%);
    justify-content: center;
`

const StyledContent = styled(StyledMainContent)`
    margin: 10rem 15rem;
    ${({ theme }) => theme.media.tablet} {
        margin: auto;
        width: 90%;
        flex-direction: column;
    }
`

const StyledSocials = styled(motion.div)`
    padding: 6rem 0 6rem 10rem;
    img {
        width: 5rem;
        margin: 0 1.2rem;
    }
`


export default Contact
