import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { fadeAnimation, fadeItem } from '../MainStyles/animations'
//images
import Image1 from '../../images/home1.svg'
import Image2 from '../../images/home2.svg'

import { useScroll } from '../MainStyles/useScroll'

import { Link } from 'gatsby'

//styles
import {
    StyledMain,
    StyledLine,
    StyledMainContent,
    StyledMainDescription,
    StyledMainText,
    StyledMainBackground,
    StyledMainSlider,
    StyledMainCards,
    StyledMainCard,
} from '../MainStyles/MainContent'

const Services = () => {
    const [element, controls] = useScroll()
    const [state] = useState({
        page: '1'
    })
    // const handlePage = (e) => {
    //     setState({
    //         ...state,
    //         [e.target.name]: e.target.value,
    //     })
    // }
    return (
        <StyledServices>
            <StyledLine />
            <StyledContent>
                <StyledMainSlider>
                    <StyledCards>
                        <StyledBackground />
                        <StyledMainCard>
                            <img src={Image1} alt="" />
                        </StyledMainCard>
                        <StyledMainCard>
                            <img src={Image2} alt="" />
                        </StyledMainCard>
                    </StyledCards>
                    <StyledNavigation>
                        {/* <button type="button" aria-label="nav-button" onClick={handlePage} name="page" value='1' className={state.page === '1' ? "active" : ""}></button>
                        <button type="button" aria-label="nav-button" onClick={handlePage} name="page" value='2' className={state.page === '2' ? "active" : ""}></button>
                        <button type="button" aria-label="nav-button" onClick={handlePage} name="page" value='3' className={state.page === '3' ? "active" : ""}></button>
                        <button type="button" aria-label="nav-button" onClick={handlePage} name="page" value='4' className={state.page === '4' ? "active" : ""}></button> */}
                    </StyledNavigation>
                </StyledMainSlider>
                <StyledDescription>
                    {state.page === '1' &&
                        <StyledMainText variants={fadeAnimation} animate={controls} initial="hidden" ref={element}>
                            <StyledH2Box>
                                <motion.h2 variants={fadeItem}>Usługi księgowe, kadrowo </motion.h2>
                                <motion.h2 variants={fadeItem}>- płacowe i&nbsp;podatkowe</motion.h2>
                            </StyledH2Box>
                            <motion.span variants={fadeItem} className="text-line"></motion.span>
                            <motion.p variants={fadeItem}>Główną działalnością naszej firmy są usługi biura rachunkowego w&nbsp;zakresie prowadzenia księgowości (ryczałt, książka przychodów i&nbsp;rozchodów, księgi handlowe (pełna księgowość), usługi kadrowo -&nbsp;płacowe i&nbsp;podatkowe. Oferujemy rejestrację i&nbsp;kompleksowe prowadzenie firm. Prowadzimy firmy jednoosobowe, spółki oraz organizacje pozarządowe.</motion.p>
                            <StyledLink to="o-nas" variants={fadeItem} whileHover={{ scale: 1.06, translateZ: '-43px', }}
                                transition={{ type: 'spring', stiffness: 300, duration: 2, }}>więcej</StyledLink>
                        </StyledMainText>
                    }
                    {state.page === '2' &&
                        <StyledMainText>
                            <h2>Usługi księgowe2</h2>
                            <span className="text-line"></span>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Unde quam numquam eum iste? Atque modi magnam, incidunt labore voluptatem unde cum natus sed blanditiis eius nobis explicabo ratione itaque laboriosam culpa fuga eaque. Accusantium, doloribus inventore. Est dolorem inventore animi.</p>
                            <button>więcej</button>
                        </StyledMainText>
                    }
                    {state.page === '3' &&
                        <StyledMainText>
                            <h2>Usługi księgowe3</h2>
                            <span className="text-line"></span>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Unde quam numquam eum iste? Atque modi magnam, incidunt labore voluptatem unde cum natus sed blanditiis eius nobis explicabo ratione itaque laboriosam culpa fuga eaque. Accusantium, doloribus inventore. Est dolorem inventore animi.</p>
                            <button>więcej</button>
                        </StyledMainText>
                    }
                    {state.page === '4' &&
                        <StyledMainText>
                            <h2>Usługi księgowe4</h2>
                            <span className="text-line"></span>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Unde quam numquam eum iste? Atque modi magnam, incidunt labore voluptatem unde cum natus sed blanditiis eius nobis explicabo ratione itaque laboriosam culpa fuga eaque. Accusantium, doloribus inventore. Est dolorem inventore animi.</p>
                            <button>więcej</button>
                        </StyledMainText>
                    }
                </StyledDescription>
            </StyledContent>
        </StyledServices>
    )
}

const StyledServices = styled(StyledMain)`
background:${({ theme }) => theme.color.lightGray};
`

const StyledContent = styled(StyledMainContent)`
margin: 10rem 0 5rem 0;
${({ theme }) => theme.media.tablet} {
    flex-direction: column-reverse;
}
${({ theme }) => theme.media.phone} {
    text-align: center;
    margin: 2rem 0 5rem 0;
}
`

const StyledDescription = styled(StyledMainDescription)`
    margin-bottom: 16rem;
    h2 {
        /* padding: 3rem 0; */
    }
    p {
        padding: 5rem 0;
    }
    .text-line {
        background: ${({ theme }) => theme.color.gray};
        width: 100%;
        height: 4px;
    }
    ${({ theme }) => theme.media.phone} {
   margin-bottom: 4rem;
}
`

const StyledBackground = styled(StyledMainBackground)`
    background: ${({ theme }) => theme.color.gray};
    border-radius: 0px 14px 14px 0px;
    left: 0%;
`

const StyledCards = styled(StyledMainCards)`
position: relative;
`

const StyledNavigation = styled.div`
    width: 90%;
    z-index: 5;
    margin-top: 11rem;
    margin-bottom: 5rem;
    display: flex;
    justify-content: center;
    button {
        width: 2.5rem;
        height: 2.5rem;
        background: ${({ theme }) => theme.color.gray};
        margin: 0 2rem;
        border-radius: 50%;
    }
    .active {
        background: ${({ theme }) => theme.color.blue};
        transition: all .4s ease;
    }
`

const StyledH2Box = styled.div`
    padding: 3rem 0;
`

const StyledLink = styled(motion(Link))`
    margin: 2rem 0;
    background: ${({ theme }) => theme.color.blue};
    border-radius: 4px;
    color: ${({ theme }) => theme.color.white};
    font-size: 2rem;
    padding: 0.6rem 10rem 0.9rem 10rem;
    font-variant: small-caps;
    box-shadow: 0px 4px 15px rgba(0, 0, 255, 0.23);
    border: none;
    transform: perspective(800px);
`

export default Services