import React from 'react'
import styled from 'styled-components'
// import { Link } from 'gatsby'
import { motion } from 'framer-motion'
import { StaticImage } from 'gatsby-plugin-image'
//images
import HomeBackground from '../../images/home-background3.jpg'

import Logo from '../../images/Logo.js'

import { fadeAnimation } from '../MainStyles/animations'
const FrontPage = () => {
    return (
        <StyledHome>
            <StaticImage
                src='../../images/home-background3.jpg'
                alt="background"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
                loading="eager"
                layout="fullWidth"
                className="home-img"
            />
            <StyledSlogan variants={fadeAnimation} initial="hidden" animate="show">
                {/* <motion.h2 variants={fadeFront}>Biuro rachunkowe <span>art7</span></motion.h2> */}
                <Logo />
                {/* <motion.h3 variants={fadeFront}>Zadbamy o Finanse Twojej firmy</motion.h3>
                <StyledLink variants={fadeFront} whileHover={{ translateY: -10, transition: { duration: .3 }, }} to="/Offer">sprawdź ofertę</StyledLink> */}
            </StyledSlogan>
        </StyledHome>
    )
}

const StyledHome = styled.section`
    min-height: 100vh;
    /* background: url(${HomeBackground}) no-repeat;
    background-size: cover;
    background-attachment: fixed; */
    display: flex;
    align-items: center;
    justify-content: center;
    .home-img {
        object-fit: cover;
        height: 100vh;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
`

const StyledSlogan = styled(motion.div)`
z-index: 2;
    width: 100%;
    margin-top: 9vh;
    background: rgba(255, 255, 255, 0.77);
    padding: 6rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    svg {
        width: 70rem;
        ${({ theme }) => theme.media.phone} {
            width: 42rem;
    }
    }
    h2 {
        font-size: 4.5rem;
        margin: 2rem 0;
        text-align: center;
        font-weight: 500;
    }
    span {
        font-size: 6.5rem;
        font-weight: 400;
        font-family: "Lobster";
    }
    h3 {
        font-size: 2.5rem;
        font-weight: 400;
        margin: 3rem 0;
    }
    ${({ theme }) => theme.media.phone} {
            margin-top: 0vh;
    }
`

// const StyledLink = styled(motion.custom(Link))`
//     font-variant: small-caps;
//     background: #4040FF;
//     border-radius: 4px;
//     color: white;
//     font-size: 2rem;
//     padding: 0.6rem 5rem 0.9rem 5rem;
//     margin: 3rem 0;
// `

export default FrontPage